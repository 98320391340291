<template>
  <b-card title="Description" > 
    <div>

        <!-- Row Loop -->
        <template   v-for="(item, index) in items"
          
         >
        <b-row
        :id="item.id"
         :key="'content-'+index"
          ref="row"
          v-if="item.remove !== true"

        >
          <b-col md="2" class="mb-2"></b-col>

          <b-col md="8" class="text-right mb-2">
            <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="outline-success"
                @click="saveItem(index)"
              >
                <feather-icon
                  icon="CheckIcon"
                  class="mr-25"
                />
                <span v-if="items[index].id">Update</span>
                <span v-else>Save</span>
              </b-button>
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-danger"
              
              @click="removeItem(index)"
            >
              <feather-icon
                icon="XIcon"
                class="mr-25"
              />
              <span>Delete</span>
            </b-button>
          </b-col>
          <b-col md="2" class="mb-2"></b-col>
          <b-col md="2">  
            <b-img
                v-if="item.layout_id == 1"
                :src="require('@/assets/images/blog/left.png')"
                height="110"
                width="170"
                class="rounded mr-2 mb-1 mb-md-0"
              />
              <b-img
                v-else-if="item.layout_id == 2"
                :src="require('@/assets/images/blog/right.png')"
                height="110"
                width="170"
                class="rounded mr-2 mb-1 mb-md-0"
              />
              <b-img
                v-else-if="item.layout_id == 3"
                :src="require('@/assets/images/blog/middle.png')"
                height="110"
                width="170"
                class="rounded mr-2 mb-1 mb-md-0"
              />
              <b-img
                v-else-if="item.layout_id == 4"
                :src="require('@/assets/images/blog/table.png')"
                height="110"
                width="170"
                class="rounded mr-2 mb-1 mb-md-0"
              />

          </b-col>
          <b-col 
          md="8"
        
            
          >
          <b-form-group
                label="Layout"
                label-for="layout"
              >
            <v-select
           
                    v-model="item.layout_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    :reduce="(option) => option.id"
                    :options="Layouts"
            />
            </b-form-group>
            <b-form-group
                label="Title"
                label-for="title"
              >
              <b-form-input
               
                 v-model="item.title"
                 autofocus
                
                 trim
                 placeholder="Title"
               
               />
        
                
              </b-form-group>
           <!-- <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-info"
              class="mt-2"
              @click="item.show = !item.show"
            >
              <feather-icon
                icon="EyeIcon"
                class="mr-25"
                v-if="!item.show"
              />
              <feather-icon
              v-else
                icon="EyeOffIcon"
                class="mr-25"
              />
              
             
            </b-button>-->
          </b-col>
          <b-col
            
            md="2"
           
          >
            
          </b-col>
          <b-col  md="2" class="mt-2" >
            
          </b-col>
          <b-col  md="8"  class="mt-2" >
            
            <b-form-group
                label="Image"
                label-for="image"
              >
              <b-card-text v-if="item.image" class="my-1">
                <b-img
                
                  :src="item.fullpath"
                 
                  width="100"
                  class="rounded mr-2 mb-1 mb-md-0"
                />
                <b-button  v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-danger" v-if="item.image" @click="revomeImage(index)" >
                  <feather-icon
                    icon="XCircleIcon"
                   
                    class="text-danger"
                
                  />
                  Remove  
                  
                </b-button>
              </b-card-text>
              <b-form-file
              
             
              v-model="item.imageFile"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
              />
             
              <b-card-text class="my-1">
                Selected file: <strong>{{ item.imageFile ? item.imageFile.name : '' }}</strong>
              </b-card-text>
        
                
              </b-form-group>
            
              <b-form-group
              v-if="item.layout_id != 4"
                label="Description"
                label-for="description"
              >
              <quill-editor :options="snowOption" v-model="item.description" />
              
                
              </b-form-group>

              <b-form-group
              v-if="item.layout_id == 4"
                label="Table"
                label-for="table"
              >
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col">รายการ</th>
                    <th scope="col">ราคา</th>
                    <th scope="col">รายละเอียด</th>
                  </tr>
                </thead>
                <tbody v-if="item.description">
                  <tr v-for="(list,indextd) in item.description">
                    <td>
                      <b-form-input
                        v-model="list.a"
                        autofocus
                        trim
                      />
                    </td>
                    <td>
                      <b-form-input
                        v-model="list.b"
                        autofocus
                        trim
                      />
                    </td><td>
                      <b-form-input
                        v-model="list.c"
                        autofocus
                        trim
                      />
                    </td>
                    <td>
                      <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="danger"
                @click="deletelisttable(index,indextd)"
                class="btn btn-sm"
              >
                <feather-icon
                  icon="MinusIcon"
                  class="mr-25"
                />
              </b-button>
                    </td>
                  </tr>
                </tbody>
              </table>
              
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="warning"
                @click="addlisttable(index)"
                class="btn btn-sm"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-25"
                />
                <span>Add</span>
              </b-button>
            </b-form-group>
          </b-col>
          <b-col  md="2"  class="mt-2" >
            
          </b-col>


          <!-- Remove Button -->
          
          <b-col cols="12">
            <hr>
          </b-col>
        </b-row>
      </template>
      
    </div>
    <b-row>
      <b-col md="2"></b-col>
      <b-col md="8"> <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      @click="repeateAgain"
    >
      <feather-icon
        icon="PlusIcon"
        class="mr-25"
      />
      <span>Add New</span>
    </b-button></b-col>
      <b-col md="2"></b-col>
    </b-row>
   

    
  </b-card>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {
  BCard, BCardText, BFormFile, BForm, BFormGroup, BFormInput, BRow, BCol, BButton,BImg
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import { ref } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import FormRepeaterBasic from './FormRepeaterBasic.vue'
import store from '@/store'
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
//import { slugify } from '@/@fake-db/utils'

import { quillEditor } from 'vue-quill-editor'


export default {
  components: {
    BCard,
    BCardText, BFormFile,
    BCardCode,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BImg,
    FormRepeaterBasic,
    vSelect,
    quillEditor
  },
  
  directives: {
    Ripple,
  },
  model: {
     prop: 'BlogInputRepeater',
    // prop: 'BlogInputRepeaterRemove',
     event: 'update:blog-input-repeater',
    // event: 'update:blog-input-repeater-remove'
   },
   props: {
    BlogInputRepeater: {
       type: Array,
       required: true,
     },
     /*BlogInputRepeaterRemove: {
       type: Array,
       required: true,
     },*/
     blog_id: {
       type: Number,
       required: false,
     },
     

   },
  mixins: [heightTransition],
  data(){
      return {
        snowOption: {
          theme: 'snow',
        },
      }
   },
  mounted() {
    //this.initTrHeight()
   
  },
  created() {
  
   // window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
   // window.removeEventListener('resize', this.initTrHeight)
  },
  setup(props, { emit }) {
    const toast = useToast()
    const items_remove = ref([]);
    const items = ref(props.BlogInputRepeater);



    const revomeImage = (index)=>{


    items.value[index].fullpath = null;
    items.value[index].image = null;
    }

    const Layouts = ref([
      {
        id:1,
        title:'Layout 1'
      },
      {
        id:2,
        title:'Layout 2'
      },
      {
        id:3,
        title:'Layout 3'
      },
      {
        id:4,
        title:'Table'
      },
    ]);
    



   

    return {
      Layouts,
      revomeImage,
      items,
      store,
      toast,
      ToastificationContent,
      items_remove

    }
  },
  methods: {
    repeateAgain() {
        this.items.push({

          id:'',
          blog_id:this.blog_id,
          layout_id: 1,
          image:null,
          imageFile:null,
          fullpath:'',
          title:'',
          description:'',
          show:false
        })
      },


      saveItem(index){
        if(this.items[index].id){

          let formData = new FormData();
          this.items[index].path = '/var/www/html/uploadfiles/uploads/music/services';
          this.items[index].savepath = 'uploads/music/services';
          this.items[index].is_active = this.items[index].is_active?1:0;
          for(const [key, value] of Object.entries(this.items[index])) {
            if(key=="description" && Array.isArray(value)){
              formData.append(key, JSON.stringify(value)); 
            }
            else{
              formData.append(key, value);  
            }  
          }

          this.store.dispatch('app-services/updateContents',{formData:formData,data:this.items[index]}).then((res)=>{
              console.log('updateContents res',res)
              this.items[index].is_active = this.items[index].is_active?true:false;
              if(res.data.success){
              this.toast({
                  component: this.ToastificationContent,
                  position: 'top-right',
                  props: {
                  title: `Success`,
                  icon: 'BellIcon',
                  variant: 'success',
                  text: `${res.data.message}`,
                  },
              });
              }else{
                this.toast({
                  component: this.ToastificationContent,
                  position: 'top-right',
                  props: {
                  title: `Error`,
                  icon: 'BellIcon',
                  variant: 'danger',
                  text: `${res.data.message}`,
                  },
              });
              
              }
            })
        }else{

          let formData = new FormData();
          this.items[index].path = '/var/www/html/uploadfiles/uploads/music/services';
          this.items[index].savepath = 'uploads/music/services';
          this.items[index].is_active = this.items[index].is_active?1:0;
          for(const [key, value] of Object.entries(this.items[index])) {
            if(key=="description" && Array.isArray(value)){
              formData.append(key, JSON.stringify(value)); 
            }
            else{
              formData.append(key, value);  
            }  
          }
          
          this.store.dispatch('app-services/addContents',{formData:formData}).then((res)=>{
              this.items[index].is_active = this.items[index].is_active?true:false;
              console.log('addContents res',res)
              if(res.data.success){
                this.items[index].id = res.data.data.insertId;
              this.toast({
                  component: this.ToastificationContent,
                  position: 'top-right',
                  props: {
                  title: `Success`,
                  icon: 'BellIcon',
                  variant: 'success',
                  text: `${res.data.message}`,
                  },
              });
              }else{
                this.toast({
                  component: this.ToastificationContent,
                  position: 'top-right',
                  props: {
                  title: `Error`,
                  icon: 'BellIcon',
                  variant: 'danger',
                  text: `${res.data.message}`,
                  },
              });
              
              }
            })
        }
      },
      removeItem(index) {
        if(this.items[index].id){

          this.$bvModal
          .msgBoxConfirm('Please confirm that you want to delete Course Detail ID.'+this.items[index].id, {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
          })
          .then(value => {
          if(value === true){
            this.store.dispatch('app-services/deleteContents',this.items[index].id).then((res)=>{
              if(res.data.success){
                this.toast({
                  component: this.ToastificationContent,
                  props: {
                    title: "Delete Success",
                    icon: 'AlertTriangleIcon',
                    variant: 'success',
                  },
                })
                this.items_remove.push({
                  id:this.items[index].id,
                })
                this.items.splice(index, 1)

                
              }
              else{
                this.toast({
                  component: this.ToastificationContent,
                  position: 'top-right',
                  props: {
                  title: `Error`,
                  icon: 'BellIcon',
                  variant: 'danger',
                  text: `${res.data.message}`,
                  },
              });
              
              }
            })
          }
          })
        }else{
          this.items.splice(index, 1)
        }

      },
    addlisttable(index){
      if(Array.isArray(this.items[index].description)){
        this.items[index].description.push({a:'',b:'',c:''})
      }
      else{
        this.items[index].description = [{a:'',b:'',c:''}]
      }
      
      
    },
    deletelisttable(index,indextd){
      this.items[index].description.splice(indextd, 1);

    },
    
   /* initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
       // this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },*/
  },
}
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>
